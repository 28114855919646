import userflow from 'userflow.js'
import logger from 'src/utils/logger'
import { EnvironmentVariables } from '../../models/utils/UtilsInterface'
import { CurrentUserDocument } from '../../models/user/BaseUserInterface'

export function initializeUserflow(publicVariables: EnvironmentVariables): void {
  try {
    userflow.init(publicVariables.USERFLOW_TOKEN)
  } catch (error) {
    logger.warn('Failed to initialize Userflow', {}, error as Error)
  }
}

export function endUserflow(): void {
  try {
    userflow.reset()
    userflow.endAll()
  } catch (error) {
    logger.warn('Failed to end Userflow', {}, error as Error)
  }
}

export function setUserflowUserContext(user: CurrentUserDocument, userflowSignature: string): void {
  try {
    userflow.identify(
      user._id.toString(),
      {
        name: user.displayName,
        firstName: user.givenName,
        lastName: user.surname,
        email: user.email,
      },
      {
        signature: userflowSignature,
      }
    )
  } catch (error) {
    logger.warn('Failed to set Userflow user context', { userId: user._id.toString() }, error as Error)
  }
}
