import { EnvironmentVariables } from '../../models/utils/UtilsInterface'
import { CurrentUserDocument } from '../../models/user/BaseUserInterface'

// A global variable to track the initialization status
let isRudderstackInitialized = false
// @ts-expect-error tsconfig disallows assigning null but it complicates implementation
let rudderstackInitializationPromise: Promise<void> = null

export async function initializeRudderstack(publicVariables: EnvironmentVariables): Promise<void> {
  if (!isRudderstackInitialized && !rudderstackInitializationPromise) {
    // the main problem is error handling but that's added explicitly
    // eslint-disable-next-line no-async-promise-executor
    rudderstackInitializationPromise = new Promise(async (resolve, reject) => {
      try {
        const rudderanalytics = await import('rudder-sdk-js')

        window.rudder = rudderanalytics

        window.rudder.load(publicVariables.RUDDERSTACK_API_KEY, 'https://coffeepalsvro.dataplane.rudderstack.com', {
          integrations: { All: true }, // load call options
        })

        window.rudder.ready(() => {
          isRudderstackInitialized = true
          resolve()
        })
      } catch (error) {
        reject(error)
      }
    })
  }

  return rudderstackInitializationPromise
}

export async function setRudderstackUserContext(user: CurrentUserDocument): Promise<void> {
  if (!isRudderstackInitialized) {
    await rudderstackInitializationPromise
  }

  window.rudder?.identify(user._id.toString())
}

export async function rudderTrack(event: string): Promise<void> {
  if (!isRudderstackInitialized) {
    await rudderstackInitializationPromise
  }

  window.rudder?.track(event)
}
