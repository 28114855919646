import { Crisp } from 'crisp-sdk-web'
import logger from 'src/utils/logger'
import { CurrentUserDocument } from '../../models/user/BaseUserInterface'
import { PublicOrganizationDocument } from '../../models/organization/OrganizationInterface'

export function initializeCrisp(): void {
  try {
    const CRISP_WEBSITE_ID = '9941baa3-d15b-4825-9b50-7b27657e7f45'
    Crisp.configure(CRISP_WEBSITE_ID, {
      sessionMerge: true,
    })

    Crisp.setSafeMode(true)
  } catch (error) {
    logger.warn('Failed to initialize Crisp', {}, error as Error)
  }
}

export function setCrispUserContext(user: CurrentUserDocument): void {
  try {
    Crisp.setTokenId(user._id.toString())
    Crisp.user.setEmail(user.email)
    Crisp.user.setNickname(`${user.givenName} ${user.surname}`)
    if (user.avatarUrl) {
      Crisp.user.setAvatar(user.avatarUrl)
    }
    Crisp.session.setData({
      'user-id': user._id.toString(),
    })
  } catch (error) {
    logger.warn('Failed to set Crisp user context', { userId: user._id.toString() }, error as Error)
  }
}

export function setCrispOrganizationContext(org: PublicOrganizationDocument): void {
  try {
    Crisp.user.setCompany(org.name, {})
    Crisp.session.setData({
      'org-name': org.name,
      'org-id': org.id,
      'is-paid-customer': org.isPayingCustomer,
    })
  } catch (error) {
    logger.warn('Failed to set Crisp organization context', { orgId: org.id }, error as Error)
  }
}

export function clearCrispContext(): void {
  try {
    // @ts-expect-error this is what the documentation said to do
    Crisp.setTokenId()
    Crisp.session.reset()
  } catch (error) {
    logger.warn('Failed to clear Crisp context', {}, error as Error)
  }
}

export function closeCrisp(): void {
  try {
    Crisp.chat.hide()
    Crisp.chat.close()
    Crisp.session.reset()
  } catch (error) {
    logger.warn('Failed to close Crisp', {}, error as Error)
  }
}
